import { tw } from '@/utils/tw';

const CheckoutFooter = tw.theme({
  slots: {
    base: `flex flex-col items-center bg-slate-200`,
    title: ``,
    background: ``,
    content: `bg-slate-900`,
    paymentGatewayLogo: ``,
  },
});

export default CheckoutFooter;
