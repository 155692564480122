import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode, useId } from 'react';
import { Checkbox } from './Checkbox';

export interface StandaloneCheckboxProps extends StandaloneComponentProps<typeof Checkbox> {
  caption?: ReactNode;
  label?: ReactNode;
  name?: string;
  status?: 'default' | 'error';
  value?: string;
}

export const StandaloneCheckbox: StandaloneComponent<StandaloneCheckboxProps> = ({
  caption,
  label,
  name,
  status,
  value,
  ...props
}) => {
  const id = useId();

  return (
    <Checkbox data-status={status} {...props}>
      <Checkbox.Label htmlFor={id}>
        <Checkbox.Input id={id} type="checkbox" {...{ name, value }} />
        {label}
      </Checkbox.Label>
      {caption && <Checkbox.Caption>{caption}</Checkbox.Caption>}
    </Checkbox>
  );
};
