import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import { useStable } from '@/hooks/useStable';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckoutBody } from 'base/components/CheckoutBody';
import { ReactNode, useMemo } from 'react';

export const MabraStandaloneCheckoutBody: typeof StandaloneCheckoutBody = (props) => {
  const bodyStable = useStable(
    useMemo(() => {
      const bodyListItems: ReactNode[] = [
        <>
          <Icon name="clock" />
          <h3>Nytt kostschema varje vecka</h3>
        </>,
        <>
          <Icon name="toolsKitchen" />
          <h3>Receptbank med över 100 recept</h3>
        </>,
        <>
          <Icon name="gym" />
          <h3>Träningsfilmer med Leila Söderholm</h3>
        </>,
        <>
          <Icon name="mailHeart" />
          <h3>Dagliga påminnelser</h3>
        </>,
        <>
          <Icon name="chats" />
          <h3>Möjlighet att ställa frågor och få svar av våra experter</h3>
        </>,
      ];
      return (
        <>
          <h4>
            Du får tillgång kostplaner som är enkla att följa, massor av nyttiga och goda recept, träningsschema och
            daglig pepp. Träningsprofilen Leila Söderholm ger dig roliga och effektiva pass varje dag – speciellt
            framtagna för 21 dagar.
          </h4>
          <h4>Häng med – utmaningen startar 27 januari!</h4>
          <h4 className="pt-4">Du får:</h4>
          {bodyListItems && bodyListItems.map((item, index) => <div key={index}>{item}</div>)}
          <h3 className="pt-4">Vad ingår i utmaningen?</h3>
          <p>
            Du får dagliga träningspass, speciellt framtagna av personliga tränaren Leila Söderholm. Du kan enkelt
            anpassa dem efter din nivå – och det tar inte mer än en kvart!
          </p>
          <p>
            I receptbanken hittar du utvalda recept, bland annat från kostrådgivartjänsten roede.com som fokuserar på
            sund mat, fysisk aktivitet och hållbara vanor.  Samtliga recept är granskade av kostrådgivare med både
            näring och smak i fokus.
          </p>
          <p>
            Du väljer själv om du vill följa en färdig kostplan eller sätta ihop din egen med hjälp av kostrådgivarens
            guide.
          </p>

          <h3>Vem kan vara med?</h3>
          <p>
            Alla! Träningspassen kan anpassas efter nivå och dagsform. Kostplanerna är enkla att följa och recepten kan
            i viss mån anpassas efter preferens.
          </p>

          <p>
            Fler funderingar?{' '}
            <Link
              href="/halsa/mabra-21-dagar-fragor-och-svar/10314614"
              content="Här hittar du vanliga frågor och svar om 21 dagar!"
              options={{ className: 'text-cherry-blossom-500 font-bold underline hover:no-underline' }}
            />
          </p>
        </>
      );
    }, []),
  );
  return <StandaloneCheckoutBody {...mergeProps({ content: bodyStable }, props)} />;
};
