import { CheckoutHeaderTheme } from '@/components/CheckoutHeader/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CheckoutHeaderTheme });

const Base = $({ as: 'div', slot: 'base' });
const Background = $({ as: 'picture', slot: 'background' });
const Content = $({ as: 'div', slot: 'content' });
const ContentImage = $({ as: 'picture', slot: 'contentImage' });
const ContentTitle = $({ as: 'h2', slot: 'contentTitle' });
const ContentDescription = $({ as: 'div', slot: 'contentDescription' });

export const CheckoutHeader = Object.assign(Base, {
  Background,
  Content,
  ContentImage,
  ContentTitle,
  ContentDescription,
});
