import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { CheckoutBody } from './CheckoutBody';

export interface StandaloneCheckoutBodyProps extends StandaloneComponentProps<typeof CheckoutBody> {
  content?: ReactNode;
}

export const StandaloneCheckoutBody: StandaloneComponent<StandaloneCheckoutBodyProps> = ({
  content,
  options,
  ...props
}) => {
  const { ...$base } = options || {};

  return (
    <CheckoutBody {...$base} {...props}>
      {content}
    </CheckoutBody>
  );
};
