import { tw } from '@/utils/tw';

const CheckoutHeader = tw.theme({
  slots: {
    base: `flex flex-col items-center bg-slate-200 px-20 py-10`,
    background: ``,
    content: ``,
    contentImage: ``,
    contentTitle: `text-headline-lg`,
    contentDescription: ``,
  },
});

export default CheckoutHeader;
