import { useEffect, useState } from 'react';

const MAX_TIMEOUT = 2147483647;
function setLargeDelayTimeout(callback: () => void, delay: number): NodeJS.Timeout {
  return setTimeout(
    () => {
      if (delay > MAX_TIMEOUT) {
        return setLargeDelayTimeout(callback, delay - MAX_TIMEOUT);
      } else {
        callback();
      }
    },
    Math.min(MAX_TIMEOUT, delay),
  );
}

export const useScheduled = (finishTime?: string) => {
  const [isFinished, setIsFinished] = useState(!!finishTime && Date.parse(finishTime) < Date.now());

  useEffect(() => {
    if (!finishTime) {
      return;
    }

    setIsFinished(!!finishTime && Date.parse(finishTime) < Date.now());

    const leftTime = Date.parse(finishTime) - Date.now();

    const timeoutId = setLargeDelayTimeout(
      () => {
        setIsFinished(true);
      },
      Math.max(leftTime, 0),
    );

    return () => clearTimeout(timeoutId);
  }, [finishTime]);

  return isFinished;
};

export default useScheduled;
