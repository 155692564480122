import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { CheckoutFooter } from './CheckoutFooter';

export interface StandaloneCheckoutFooterProps extends StandaloneComponentProps<typeof CheckoutFooter> {
  title?: ReactNode;
  background?: ReactNode;
  content?: ReactNode;
  paymentGatewayLogo?: ReactNode;
}

export const StandaloneCheckoutFooter: StandaloneComponent<StandaloneCheckoutFooterProps> = ({
  title,
  background,
  content,
  paymentGatewayLogo,
  ...props
}) => {
  return (
    <CheckoutFooter id={'checkout-footer'} {...props}>
      {background && <CheckoutFooter.Background>{background}</CheckoutFooter.Background>}
      {title && <CheckoutFooter.Title>{title}</CheckoutFooter.Title>}
      <CheckoutFooter.Content>{content}</CheckoutFooter.Content>
      {paymentGatewayLogo && (
        <CheckoutFooter.PaymentGatewayLogo>{paymentGatewayLogo}</CheckoutFooter.PaymentGatewayLogo>
      )}
    </CheckoutFooter>
  );
};
