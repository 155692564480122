import { ButtonWith } from '@/components/Button';
import { useClassName, useTheme } from '@/styles/KlarnaPayment';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const Button = ButtonWith({ theme: { useTheme } });
const GenericComponent = GenericComponentFactory({ useClassName });

const Section = GenericComponent({ as: 'div', styles: { key: '' } });
const Content = GenericComponent({ as: 'div', styles: { key: 'content' } });

export const KlarnaPayment = Object.assign(Section, {
  Content,
  Button,
});
