/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneInputCaption as ResolvedStandaloneInputCaption } from 'base/components/standalone/InputCaption';

export const InputCaption: typeof ResolvedStandaloneInputCaption = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneInputCaption {...props} />
        </ErrorBoundary>
    );
});

export type InputCaptionProps = PropsFromComponent<typeof InputCaption>;

/** @deprecated Use slot architecture instead */
export const InputCaptionWith = (extras: DynamicStandaloneExtras): typeof InputCaption => {
    return function InputCaption(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'InputCaption');
        return <ResolvedStandaloneInputCaption {...mergeProps({ options: { theme } }, props)} />;
    }
}