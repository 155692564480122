/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "button": {
    "extend": {
      "variant_default": "self-center",
      "size_medium": "mt-3",
      "size_large": "mt-3",
      "size_small": "mt-3"
    }
  },
  "textField": {
    "extend": {
      "label_size_default": "!text-headline-sm",
      "group_size_default": "mb-0",
      "caption_colors_primary": "group-data-[status=error]:text-error-500",
      "caption_size_default": "text-headline-2xs"
    }
  },
  "checkbox": {
    "extend": {
      "variant_default": "grid grid-cols-[min-content_1fr] items-center group",
      "label_colors_default": "text-black [&_a]:text-cherry-blossom-500 group-data-[status=error]:text-error-500",
      "label_size_default": "text-headline-xs [&_a]:font-bold",
      "label_variant_default": "cursor-pointer [&_a]:hover:underline",
      "caption_colors_default": "text-black group-data-[status=error]:text-error-500",
      "caption_size_default": "text-headline-2xs",
      "caption_variant_default": "col-span-2"
    }
  },
  "klarnaPayment": {},
  "form_colors_default": "",
  "form_size_default": "px-8 md:px-24 lg:px-58 gap-y-4 m-2 mt-0 sm:m-4 sm:mt-0 lg:m-8 lg:mt-0",
  "form_variant_default": "flex flex-col",
  "form_errors_colors_default": "",
  "form_errors_size_default": "",
  "form_errors_variant_default": "",
  "form_errors_message_colors_default": "text-error-500",
  "form_errors_message_size_default": "text-headline-2xs",
  "form_errors_message_variant_default": "flex flex-col",
  "form_policy_colors_default": "",
  "form_policy_size_default": "text-headline-xs",
  "form_policy_variant_default": "",
  "closed_colors_default": "",
  "closed_size_default": "px-8 md:px-16 lg:px-48 text-headline-sm md:text-headline-xl mb-8 md:mb-15 mt-3 md:mt-10",
  "closed_variant_default": "text-center",
  "colors_default": "",
  "size_default": "gap-y-10",
  "variant_default": "flex flex-col"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Checkout");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;