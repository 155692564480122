import { TextFieldProps, TextFieldWith } from '@/components/TextField';
import { useTheme } from '@/styles/Checkout';
import { Component } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';

type Base = { name: string; rules?: UseControllerProps['rules'] };

type CheckoutPaymentFormTextFieldComponentProps = Base & TextFieldProps;

const TextField = TextFieldWith({ theme: { useTheme } });

export const CheckoutFormTextFieldComponent: Component<CheckoutPaymentFormTextFieldComponentProps> = ({
  name,
  rules,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field: { ref, ...field } }) => {
        const error = name && (errors[name]?.message as string);
        return (
          <TextField
            caption={error}
            {...field}
            {...props}
            data-field={name}
            data-status={error ? 'error' : ''}
            {...mergeProps({ options: { $input: { defaultValue: field.value } } }, props)}
          />
        );
      }}
      name={name}
    />
  );
};
