/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneCheckbox as ResolvedStandaloneCheckbox } from 'base/components/standalone/Checkbox';

export const Checkbox: typeof ResolvedStandaloneCheckbox = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneCheckbox {...props} />
        </ErrorBoundary>
    );
});

export type CheckboxProps = PropsFromComponent<typeof Checkbox>;

/** @deprecated Use slot architecture instead */
export const CheckboxWith = (extras: DynamicStandaloneExtras): typeof Checkbox => {
    return function Checkbox(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Checkbox');
        return <ResolvedStandaloneCheckbox {...mergeProps({ options: { theme } }, props)} />;
    }
}