import { tw } from '@/utils/tw';
import BaseCheckoutHeaderTheme from 'base/components/CheckoutHeader/theme';

const CheckoutHeader = tw.theme({
  extend: BaseCheckoutHeaderTheme,
  slots: {
    base: `relative left-[calc(-50vw_+_50%)] w-screen overflow-hidden bg-yellow-sea-100 px-0 pb-6 pt-10`,
    background: `absolute -top-18 left-0 w-full text-yellow-sea-400 sm:-top-32 md:-top-47 lg:bottom-25 lg:top-auto`,
    content: `flex flex-col items-center px-8 md:px-24 lg:max-w-[1050px]`,
    contentImage: `flex h-16 w-20`,
    contentTitle: `text-center text-headline-lg md:text-headline-2xl`,
    contentDescription: `mt-3 text-center text-headline-xs`,
  },
});

export default CheckoutHeader;
