import { CheckoutFooterTheme } from '@/components/CheckoutFooter/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CheckoutFooterTheme });

const Base = $({ as: 'div', slot: 'base' });
const Title = $({ as: 'h2', slot: 'title' });
const Background = $({ as: 'picture', slot: 'background' });
const Content = $({ as: 'div', slot: 'content' });
const PaymentGatewayLogo = $({ as: 'div', slot: 'paymentGatewayLogo' });

export const CheckoutFooter = Object.assign(Base, {
  Background,
  Title,
  Content,
  PaymentGatewayLogo,
});
