import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { CheckoutHeader } from './CheckoutHeader';

export interface StandaloneCheckoutHeaderProps extends StandaloneComponentProps<typeof CheckoutHeader> {
  background?: ReactNode;
  image?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
}

export const StandaloneCheckoutHeader: StandaloneComponent<StandaloneCheckoutHeaderProps> = ({
  background,
  image,
  title,
  description,
  ...props
}) => {
  return (
    <CheckoutHeader {...props}>
      {background && <CheckoutHeader.Background>{background}</CheckoutHeader.Background>}
      <CheckoutHeader.Content>
        {image && <CheckoutHeader.ContentImage>{image}</CheckoutHeader.ContentImage>}
        {title && <CheckoutHeader.ContentTitle>{title}</CheckoutHeader.ContentTitle>}
        {description && <CheckoutHeader.ContentDescription>{description}</CheckoutHeader.ContentDescription>}
      </CheckoutHeader.Content>
    </CheckoutHeader>
  );
};
