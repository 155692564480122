import { ButtonProps, ButtonWith } from '@/components/Button';
import { useTheme } from '@/styles/Checkout';
import { StandaloneComponent } from '@/types/component';

export type CheckoutFormButtonComponentProps = ButtonProps;
const Button = ButtonWith({ theme: { useTheme } });

export const CheckoutFormButtonComponent: StandaloneComponent<CheckoutFormButtonComponentProps> = ({ ...props }) => {
  return <Button {...props} />;
};
