/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_default": "[&_div]:bg-yellow-sea-100 [&_svg]:text-yellow-sea-500",
  "size_default": "px-8 md:px-24 lg:px-64 gap-y-4 [&_p]:text-body-md [&_h3]:text-headline-sm [&_div_h3]:text-headline-xs sm:[&_div_h3]:text-headline-sm [&_h4]:text-headline-xs [&_div]:p-3 [&_div]:gap-x-3 [&_svg]:h-7 [&_svg]:w-7 [&_svg]:shrink-0",
  "variant_default": "flex flex-col [&_div]:flex [&_div]:flex-row [&_div]:items-center [&_div]:rounded-xl"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "CheckoutBody");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;