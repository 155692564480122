import { useCallback, useEffect, useMemo, useState } from 'react';
import { tryGetWindowProp } from '@/utils/tryGetWindowProp';

interface KlarnaPaymentProps {
  paymentContainerId: string;
}

export const useKlarnaPayment = ({ paymentContainerId }: KlarnaPaymentProps) => {
  const [paymentFormEnabled, setPaymentFormEnabled] = useState(false);
  const [klarna, setKlarna] = useState<Klarna>();

  const init = useCallback(
    (clientToken: string) => {
      klarna?.Payments.init({
        client_token: clientToken,
      });
    },
    [klarna?.Payments]
  );

  const load = useCallback(() => {
    klarna?.Payments.load(
      {
        container: `#${paymentContainerId}`,
      },
      {},
      ({ show_form, error }) => {
        setPaymentFormEnabled(show_form);
      }
    );
  }, [klarna?.Payments, paymentContainerId]);

  const authorize = useCallback<
    () => Promise<{ show_form: boolean; approved: boolean; authorization_token: string }>
  >(() => {
    return new Promise((resolve) => {
      klarna?.Payments.authorize({}, {}, function (res) {
        resolve({
          show_form: res.show_form,
          approved: res.approved,
          authorization_token: res.authorization_token,
        });
      });
    });
  }, [klarna?.Payments]);

  useEffect(() => {
    tryGetWindowProp<Klarna>('Klarna').then((klarna: Klarna) => setKlarna(klarna));
  }, []);

  return useMemo(
    () => ({
      init,
      load,
      authorize,
      paymentFormEnabled,
      klarnaReady: Boolean(klarna),
    }),
    [init, load, authorize, paymentFormEnabled, klarna]
  );
};
