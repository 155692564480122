import { useStable } from '@/hooks/useStable';
import HeaderBackground from '@/mabra/public/image/course/checkout-header-background.svg';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { mergeProps } from '@/utils/merge';
import { StandaloneCheckoutHeader, StandaloneCheckoutHeaderProps } from 'base/components/CheckoutHeader';
import NextImage from 'next/image';
import { useMemo } from 'react';
import { Button } from '@/components/Button';

export const MabraStandaloneCheckoutHeader: typeof StandaloneCheckoutHeader = (props) => {
  const logo = useMemo(() => <NextImage src={BannerImage} alt={'Banner Image'} />, []);

  const headerStable = useStable({
    background: useMemo(() => <HeaderBackground />, []),
    image: logo,
    title: '21 dagar kost & träning',
    description: useMemo(
      () => (
        <>
          <p>Ge dig själv 21 dagar för att hitta nya hälsosamma vanor – som går att hålla fast vid resten av livet!</p>
          <p>
            Gör som 10 000 andra – anmäl dig till MåBra:s hyllade utmaning. Du får daglig pepp och coachning av vår
            erfarna träningsexpert och kostrådgivare Sandra Hiort.
          </p>
          <Button
            content={'Anmäl dig här'}
            options={{
              className: 'mt-4',
              onClick: () =>
                document?.getElementById('checkout-footer')?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
            }}
          />
        </>
      ),
      [],
    ),
  }) satisfies StandaloneCheckoutHeaderProps;

  return <StandaloneCheckoutHeader {...mergeProps({ ...headerStable }, props)} />;
};
